import App from './App.vue'
import { createApp} from 'vue'
import router from './router'
import store from './store'
import { createAuth0 } from "@auth0/auth0-vue";
import axios from 'axios'
import VueAxios from 'vue-axios'
import './index.css'
import FontAwesomeIcon from './assets/fontawesome-icons';
import './assets/tailwind.css'

const app = createApp(App);

app
  .use(router)
  .use(
    createAuth0({
      domain: process.env.VUE_APP_AUTH0_DOMAIN,
      client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
      redirect_uri: process.env.VUE_APP_AUTH0_CALLBACK_URL,
      audience: process.env.VUE_APP_AUTH0_AUDIENCE
    })
  )
  .use(VueAxios, axios)
  .use(store)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');
