<template>
  <button class="button button--primary button--compact" @click="logout">
    Log Out
  </button>
</template>

<script>
import { useAuth0 } from '@auth0/auth0-vue';
import {useStore} from "vuex";
export default {
  name: "LogoutButton",
  setup() {
      const { logout } = useAuth0();
      const store = useStore();
      return {
        logout: () => {
          logout({ returnTo: store.state.baseURL });
        }
      };
    }
};
</script>