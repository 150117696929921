<template>
  <Header :title="'Welcome back, ' + user.name" icon="home" />
  <!--<DoughnutChart />-->
  <div class="p-8 h-80">
    <LineChart />
  </div>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-8">
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <Stats v-for="item in stats" :key="item.id" :name="item.name" :stat="item.stat" :change="item.change" :changeType="item.changeType" />
    </dl>
  </div>

</template>

<script>
import { useAuth0 } from '@auth0/auth0-vue';
import Header from '@/components/Header.vue'
//import DoughnutChart from '@/components/dataviz/DoughnutChart.vue'
import LineChart from '@/components/dataviz/LineChart.vue'
import Stats from '@/components/tailwindui/Stats.vue'

const stats = [
  { id: 1, name: 'Total Products', stat: '127', change: '122', changeType: 'increase' },
  { id: 2, name: 'Total Active Policies', stat: '1,489', change: '5.4%', changeType: 'increase' },
  { id: 3, name: 'Avg. Commission/Policy', stat: '$5.78', change: '3.2%', changeType: 'decrease' },
];

export default {
  name: 'Home',
  components: {
    Header,
    //DoughnutChart,
    LineChart,
    Stats
  },
  setup() {
    const { user } = useAuth0();
    return {
        user,
        stats
    }
  },
}
</script>

