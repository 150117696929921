import { createStore } from 'vuex'

const store = createStore({
  state () {
    return {
      airtableAPI: 'https://api.airtable.com/v0/',
      airtableBase: '',
      airtableToken: '',
      airtableURL: '',
      airtableHeaders: '',
      employer: '',
      baseURL: process.env.VUE_APP_API_SERVER_URL
    }
  },
  mutations: {
    setUser (state, payload) {
      state.airtableBase = payload.airtableBase,
      state.airtableToken = payload.airtableToken,
      state.employer = payload.employer,
      state.airtableURL = state.airtableAPI + state.airtableBase,
      state.airtableHeaders = { Authorization: 'Bearer '+ store.state.airtableToken}
    }
  }
})

export default store