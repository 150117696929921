<template>
<div class="flex">
  <div class="flex-none w-64">
    <NavigationDrawer />
  </div>
  <main class="flex-1">
    <router-view />
  </main>
  </div>
</template>

<script>
import { provide } from "vue";
import NavigationDrawer from '@/components/navigation/NavigationDrawer.vue'


import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { ApolloClients } from '@vue/apollo-composable'
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: 'https://api.baseql.com/airtable/graphql/appsAnwRkeOkGEzOt',
})
const cache = new InMemoryCache()
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
})


export default {
  name: 'App',
  components: {
    NavigationDrawer
  },
  setup() {
    provide(ApolloClients, {
      default: apolloClient,
    })
  },
}
</script>