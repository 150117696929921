import { library } from '@fortawesome/fontawesome-svg-core';
import { faHouse } from '@fortawesome/pro-solid-svg-icons';
import { faShield } from '@fortawesome/pro-solid-svg-icons';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons';
import { faCity } from '@fortawesome/pro-solid-svg-icons';
import { faIndustry } from '@fortawesome/pro-solid-svg-icons';
import { faScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons';

import { faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faHouse);
library.add(faShield);
library.add(faUser);
library.add(faUsers);
library.add(faBuilding);
library.add(faCity);
library.add(faIndustry);
library.add(faScrewdriverWrench);

library.add(faArrowUp);
library.add(faArrowDown);

export default FontAwesomeIcon;