<template>
  <div />
</template>

<script>
import { useAuth0 } from '@auth0/auth0-vue';
import {useStore} from "vuex";

export default {
  name: 'Callback',
  setup() {
    const { user } = useAuth0();
    return {
        user
    }
  },
  mounted() {
      const store = useStore();
    store.commit('setUser', {
        airtableBase: this.user['http://localhost:8080/airtableBase'],
        airtableToken: this.user['http://localhost:8080/airtableKey'],
        employer: this.user['http://localhost:8080/employer'],
    });
  }
}
</script>