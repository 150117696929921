<!-- This example requires Tailwind CSS v2.0+ -->
<template>

      <div class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
        <dt>
          <p class="text-sm font-medium text-gray-500 truncate">{{ name }}</p>
        </dt>
        <dd class="pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">
            {{ stat }}
          </p>
          <p :class="[changeType === 'increase' ? 'text-green-600' : 'text-red-600', 'ml-2 flex items-baseline text-sm font-semibold']">
            <font-awesome-icon v-if="changeType === 'increase'"  icon="arrow-up" size="sm" class="self-center flex-shrink-0 text-green-500" aria-hidden="true" />
            <font-awesome-icon v-else  icon="arrow-down" size="sm" class="self-center flex-shrink-0 text-red-600" aria-hidden="true" />
            <span class="sr-only"> {{ changeType === 'increase' ? 'Increased' : 'Decreased' }} by </span>
            {{ change }}
          </p>
          <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div class="text-sm">
              <a href="#" class="font-medium text-blue-600 hover:text-indigo-500">
                View all<span class="sr-only"> {{ name }} stats</span></a>
            </div>
          </div>
        </dd>
      </div>

</template>

<script>


export default {
  components: {
    
  },
  props: {
    name: String,
    stat: String,
    change: String,
    changeType: String,
    icon: String
  },
  setup() {
    return {
      
    }
  },
}
</script>