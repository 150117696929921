import { createRouter, createWebHistory } from 'vue-router';

//import Login from "../views/Login.vue";
import Container from "../views/app/Index.vue";
import Home from "../views/app/Home.vue";

import Products from "../views/app/Products.vue";
import Product from "../views/app/products/Product.vue";
import Producers from "../views/app/Producers.vue";
import Clients from "../views/app/Clients.vue";
import Markets from "../views/app/Markets.vue";
import Industries from "../views/app/Industries.vue";
import Tools from "../views/app/Tools.vue";


import Callback from "../components/auth/Callback.vue";

import { authGuard } from "@auth0/auth0-vue";

const routes = [
      {
        path: '/',
        component: Container,
        beforeEnter: authGuard,
        children: [
          { 
            path: 'home', 
            name: 'home',
            component: Home,
            beforeEnter: authGuard
          },
          { 
            path: 'products', 
            name: 'products',
            component: Products,
            beforeEnter: authGuard
          },
          {
            path: 'product/:id',
            name: 'product',
            component: Product,
            props: true
          },
          { 
            path: '/producers', 
            name: 'producers',
            component: Producers,
            beforeEnter: authGuard
          },
          { 
            path: '/clients', 
            name: 'clients',
            component: Clients,
            beforeEnter: authGuard
          },
          { 
            path: '/markets', 
            name: 'markets',
            component: Markets,
            beforeEnter: authGuard
          },
          { 
            path: '/industries', 
            name: 'industries',
            component: Industries,
            beforeEnter: authGuard
          },
          { 
            path: '/tools', 
            name: 'tools',
            component: Tools,
            beforeEnter: authGuard
          },
        ]
      },
      {
        path: "/callback",
        name: "callback",
        component: Callback
      }
    ]

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
})

export default router