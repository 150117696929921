
<template>
  <Header title="Tools" icon="screwdriver-wrench" />

  <Suspense>
    <template #default>
      <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-8">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Client
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Messaging Service
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Holly Health Library
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in results" :key="item.Producer_ID">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                              {{ item.fields.Client_Name }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <Switch v-model="item.fields.Holly_Access" :class="[item.fields.Holly_Access ? 'bg-blue-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                        <span class="sr-only">Use setting</span>
                        <span aria-hidden="true" :class="[item.fields.Holly_Access ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                      </Switch>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <Switch v-model="item.fields.Holly_Access" :class="[item.fields.Holly_Access ? 'bg-blue-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                        <span class="sr-only">Use setting</span>
                        <span aria-hidden="true" :class="[item.fields.Holly_Access ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                      </Switch>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #fallback>
      Loading
    </template>
  </Suspense>
</template>

<script>
import {useStore} from "vuex";
import axios from "axios"
import { onMounted } from '@vue/runtime-core'
import { ref } from "vue";
import Header from '@/components/Header.vue'
import { Switch } from '@headlessui/vue'

export default {
    name: 'Toolsd',
    components: {
      Header,
      Switch
    },
    setup() {
        const store = useStore();
        const offset = ref();
        const results = ref([]);
        onMounted(async() => {
          const clients = await axios.get(store.state.airtableURL + "/Clients", { params: { offset: offset.value }, headers: store.state.airtableHeaders })
          results.value = clients.data.records;
          offset.value = clients.data.offset;
          console.log(results.value);
        });
        return { 
            results
        }
    }
}
</script>