<template>
    <nav class="flex-1 space-y-1">
        <a href="#" class="text-blue-50 hover:bg-blue-600 hover:text-blue-200 group flex items-center px-2 py-2 text-sm font-normal rounded-md">
            <font-awesome-icon icon="user" size="sm" class="text-blue-50 group-hover:text-blue-50 mr-3 flex-shrink-0" aria-hidden="true" />
            {{ user.name }}
        </a>
        <a href="#" class="text-blue-50 hover:bg-blue-600 hover:text-blue-200 group flex items-center px-2 py-2 text-sm font-normal rounded-md">
            <LogoutButton />
        </a>
        <div class="flex items-center">
            <span class="text-sm ml-2 text-blue-300">Version 0.1</span>
        </div>
    </nav>
</template>


<script>
import { useAuth0 } from '@auth0/auth0-vue';
import {useStore} from "vuex";
import LogoutButton from "@/components/auth/logout-button.vue";

export default {
  name: 'NavigationDrawerBottomMenu',
  components: { LogoutButton },
  setup() {
    const { isAuthenticated, isLoading, user } = useAuth0();
    return {
        isAuthenticated,
        isLoading,
        user
    }
  },
  mounted() {
      const store = useStore();
    store.commit('setUser', {
        airtableBase: this.user['http://localhost:8080/airtableBase'],
        airtableToken: this.user['http://localhost:8080/airtableKey'],
        employer: this.user['http://localhost:8080/employer'],
    });
  }
}
</script>