<template>
  <header class="bg-white">
    <div class="border-b border-slate-200 py-4 px-4">
      <div class="flex items-center">
        <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-blue-50">
          <font-awesome-icon :icon="icon" size="md" class="text-blue-800 leading-none" aria-hidden="true" />
        </span>
        <h1 class="text-2xl font-semibold text-slate-800 ml-4">{{ title }}</h1>
      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: 'Header',
  props: {
    title: String,
    icon: String
  },
  components: {
    
  },
  setup() {
    
  }
}
</script>