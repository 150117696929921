<template>
    <nav class="flex-1 space-y-1">
        <router-link v-for="item in navigation" :key="item.name" :to="item.href" class="text-blue-50 hover:bg-blue-600 hover:text-blue-200 group flex items-center px-2 py-2 text-sm font-normal rounded-md">
            <font-awesome-icon :icon="item.icon" size="sm" class="text-blue-50 group-hover:text-blue-50 mr-3 flex-shrink-0" aria-hidden="true" />
            {{ item.name }}
        </router-link>
    </nav>
</template>

<script>

const navigation = [
  { name: 'Home', href: '/home', icon: 'house'},
  { name: 'Products', href: '/products', icon: 'shield'},
  { name: 'Producers', href: '/producers', icon: 'users'},
  { name: 'Clients', href: '/clients', icon: 'building'},
  { name: 'Markets', href: '/markets', icon: 'city'},
  { name: 'Industries', href: '/industries', icon: 'industry'},
  { name: 'Tools', href: '/tools', icon: 'screwdriver-wrench'}
]

export default {
  name: 'NavigationDrawerTopMenu',
  setup() {
      return {
          navigation
      }
  }
}
</script>

<style scoped>
.router-link-active {
    background-color: theme('colors.blue.600');
    color: theme('colors.blue.50');
}
</style>