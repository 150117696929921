<template>
  <Header title="Industries" icon="industry" />
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: 'Industries',
  components: {
    Header
  }
}
</script>