<template>
  <Header title="Products" icon="shield" />

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-8">
        <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <Stats v-for="item in stats" :key="item.id" :name="item.name" :stat="item.stat" :change="item.change" :changeType="item.changeType" />
        </dl>
      </div>

  <Suspense>
    <template #default>
      <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-8">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Carrier
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Product Name
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Active Enrollments
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Participation Rate
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in productResults" :key="item.id">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                              {{ item.fields.Carrier_Name[0] }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                            <router-link :to="{ name: 'product', params: { id: item.fields.Product_ID, product: item.fields.Product_Name }}" class="text-blue-600"> 
                              {{ item.fields.Product_Name }}
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">{{ item.fields["Count (Policies)"] }}</div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {{ item.fields.fldyo8oeAfiHnNTFV }}
                      </span>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #fallback>
      Loading
    </template>
  </Suspense>
</template>

<script>
import {useStore} from "vuex";
import axios from "axios"
import { onMounted } from '@vue/runtime-core'
import { ref } from "vue";
//import { ref, computed } from "vue";
import Header from '@/components/Header.vue'
import Stats from '@/components/tailwindui/Stats.vue'

const stats = [
  { id: 1, name: 'Total Products', stat: '127', change: '122', changeType: 'increase' },
  { id: 2, name: 'Total Active Policies', stat: '1,489', change: '5.4%', changeType: 'increase' },
  { id: 3, name: 'Avg. Commission/Policy', stat: '$5.78', change: '3.2%', changeType: 'decrease' },
];

export default {
    name: 'Insights',
    components: {
      Header,
      Stats
    },
    setup() {
        const store = useStore();
        const offset = ref();
        const productResults = ref([]);
        //const totalPolicies = ref([]);
        /*const totalProducts = computed(() => {
          return productResults.value.length;
        })*/
        onMounted(async() => {
          // Need to create loop to fetch all results when airtable only responds with max 100 records
          
          const products = await axios.get(store.state.airtableURL + "/Products", { params: { offset: offset.value }, headers: store.state.airtableHeaders })
          productResults.value = products.data.records;
          offset.value = products.data.offset;



            //const prototypeValues = await axios.get(airtableURL + "/tblas94ly2yUQerME?filterByFormula={fld26gEcLdt8Y6sGJ}={Active Policy}", { headers: airtableHeaders })
            //totalPolicies.value = prototypeValues.data.records;
            //console.log("Data: " + products.data);
            //console.log("Offset: " + products.data.offset);
            //console.log("Status: " + products.status);
            //console.log("Status Text: " + products.statusText);
            //console.log("Headers: " + products.headers);
            //console.log("Config: " + products.config);
            //console.log(prototypeValues);
            //console.log(store.state.airtableBase);
            //console.log("Total Products: " + totalProducts.value);
        });
        return { 
            productResults,
            stats,
            //totalPolicies
        }
    }
}
</script>