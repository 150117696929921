<template>
  <div class="bg-blue-50">
    <Error />
    <router-view />
  </div>
</template>

<script>
import Error from "./components/auth/Error";

export default {
  name: 'App',
  components: {
    Error
  },
  setup() {
    
  },
}
</script>