<template>
    <LineChart v-bind="lineChartProps" :options="options" :height="320"/>
</template>

<script>
import { defineComponent, ref } from "vue";
import { LineChart, useLineChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);
export default defineComponent({
  name: "App",
  components: { LineChart },
  setup() {
    const options = ref({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
            display: false,
        },
        title: {
          display: false,
        },
        line: {
            fill: true,
        }
      },
      scales: {
          yAxes: {
              display: false,
          }
      }
    });
    const dataValues = ref([3500000, 4000000, 6000000, 7000000, 7200000]);
    const dataLabels = ref(["Year 1", "Year 2", "Year 3", "Year 4", "Year 5"]);
    const testData = {
      labels: dataLabels.value,
      datasets: [
        {
          data: dataValues.value,
          backgroundColor: "rgba(30, 64, 175, 0.5)",
          borderWidth: 2,
          borderColor: "#1E40AF",
          lineTension: .3,
          fill: true,
        },
      ],
    };
    const { lineChartProps, lineChartRef } = useLineChart({
      chartData: testData,
    });
    return {
      testData,
      lineChartRef,
      lineChartProps,
      options
    };
  },
});
</script>

