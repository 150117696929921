<template>
  <Header :title="'Policies: '+productName" icon="shield" />
  <Suspense>
    <template #default>
      <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-8">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Product Name
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Employer
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in policiesResults" :key="item.Enrollment_ID">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                            {{ item.fields.Employee_Name[0] }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                        {{ item.fields.Employer[0] }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          {{ item.fields.Status }}
                      </span>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #fallback>
      Loading
    </template>
  </Suspense>
</template>

<script>
import {useStore} from "vuex";
import { useRoute } from 'vue-router';
import axios from "axios"
import { onMounted } from '@vue/runtime-core'
import { ref } from "vue";
import Header from '@/components/Header.vue'


export default {
    name: 'Insights',
    components: {
      Header,
    },
    setup() {
        const route = useRoute();
        const store = useStore();
        const offset = ref();
        const policiesResults = ref([]);
        const productName = route.params.product
        onMounted(async() => {
            const id = route.params.id
            const policies = await axios.get(store.state.airtableURL + "/Policies?filterByFormula={Product_ID}="+id, { params: { offset: offset.value }, headers: store.state.airtableHeaders })
            policiesResults.value = policies.data.records;
            offset.value = policies.data.offset;
            console.log(policiesResults.value);
        });
        return { 
            policiesResults,
            productName
        }
    }
}
</script>