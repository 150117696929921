<template>
  <Header title="Producers" icon="users" />

  <Suspense>
    <template #default>
      <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-8">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Producer
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      New Bussiness Goal
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Retention Goal
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in results" :key="item.Producer_ID">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                              {{ item.fields.Producer_Name }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">{{ item.fields.New_Business_Goal }}</div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {{ item.fields.Retention_Goal }}
                      </span>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #fallback>
      Loading
    </template>
  </Suspense>
</template>

<script>
import {useStore} from "vuex";
import axios from "axios"
import { onMounted } from '@vue/runtime-core'
import { ref } from "vue";
import Header from '@/components/Header.vue'


export default {
    name: 'Insights',
    components: {
      Header
    },
    setup() {
        const store = useStore();
        const offset = ref();
        const results = ref([]);
        onMounted(async() => {
          const producers = await axios.get(store.state.airtableURL + "/Producers", { params: { offset: offset.value }, headers: store.state.airtableHeaders })
          results.value = producers.data.records;
          offset.value = producers.data.offset;
        });
        return { 
            results
        }
    }
}
</script>