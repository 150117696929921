<template>
  <Header title="Markets" icon="city" />
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: 'Markets',
  components: {
    Header
  }
}
</script>